import { Link } from 'gatsby';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import arrow from '../assets/images/right-arrow-white.svg';
import efficiency from '../assets/images/efficiency.svg';

const Efficiency = () => {
  return (
    <div className="py-4 py-lg-5" id="efficiency">
      <Container className="d-flex">
        <div className="d-flex flex-column justify-content-center align-items-center align-items-lg-start">
          <div className="fw-600 fnt-16 fnt-md-24 fnt-lg-28  mb-4 text-center base-color efficiency-header">
            Companies witness up to 30% increase in development and QA
            efficiency
          </div>
          <div>
            <div className="fw-400 fnt-sm-14 fnt-16 mb-4 text-center efficiency-subheading">
              In business, time is money. Don’t let valuable QA and developer
              hours be wasted on repetitive tasks. With bugs.work, you can
              report bugs effortlessly. Our platform ensures that your team
              spends less time on mundane tasks and more time on innovation and
              growth.
            </div>

            <div className="fw-400 fnt-sm-14 fnt-16 mb-4 text-center efficiency-subheading">
              bugs.work not only enhances productivity but also improves the
              quality and reliability of your application. You can create
              detailed bug reports in seconds ,allowing your team to focus on
              what they do best. Maximize your efficiency and maintain your
              competitive edge with bugs.work!
            </div>
          </div>
          <div className="">
            <Link
              to={`${process.env.GATSBY_APP_URL}/registration/`}
              target="_blank"
            >
              <Button size="lg" className="font-14 fw-500 btn-contact-us">
                Start for free <img src={arrow} alt="arrow" className="ms-2" />
              </Button>
            </Link>
          </div>
        </div>
        <div className="d-lg-flex flex-column justify-content-center d-none d-lg-block">
          <img src={efficiency} alt="efficiency" />
        </div>
      </Container>
    </div>
  );
};

export default Efficiency;

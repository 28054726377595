import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import TestimonialsDetails from './TestiMonialsDetails';
import Testimonials from '../json/testimonial.json';
import '../assets/scss/testimonial.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import leftArrow from '../assets/images/left-arrow.svg';
import rightArrow from '../assets/images/right-arrow.svg';

function Clients() {
  const CustomPrevArrow = (props: any) => (
    <div
      {...props}
      style={{
        position: 'absolute',
        zIndex: 1,
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <img src={leftArrow} alt="Previous" />
    </div>
  );

  const CustomNextArrow = (props: any) => (
    <div
      {...props}
      style={{
        position: 'absolute',
        zIndex: 1,
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <img src={rightArrow} alt="Next" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="client-bg py-4 py-md-5">
      <Container>
        <div className=" fnt-32 ask-ques fw-600 mb-4 text-white text-center">
          Hear what our customers have to say!
        </div>
        <div className=" ms-3  m-auto slider-container">
          <div className="background-box ms-3 ms-md-0"></div>
          <Slider {...settings} className="testimonial-slider-section mb-3 mb-md-4 mb-lg-5">
            {Testimonials.map((Testimonial: any) => (
              <div key={Testimonial.id} className="">
                <TestimonialsDetails testimonial={Testimonial} />
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
}

export default Clients;

import * as React from 'react';
import '../assets/scss/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Acnavbar from '../components/Acnavbar';
import { useEffect } from 'react';
import Hero from '../components/Hero';
import Clients from '../components/Clients';
import Services from '../components/Services';
import AskedQuestions from '../components/AskedQuestions';
import Footer from '../components/footer';
import Seo from '../shared/seo';
import Wrapper from '../components/Wrapper';
import bugsworkfavicon from '../assets/images/favicon.png';
import useUtmTracking from '../util/utm';
import { Crisp } from 'crisp-sdk-web';
import Features from '../components/Features';
import Integration from '../components/Integration';
import Efficiency from '../components/Efficiency';
import '../assets/scss/testimonial.scss';

const IndexPage = () => {
  const method = useUtmTracking();
  useEffect(() => {
    method();
    Crisp.configure('d2b8a73f-d2f8-4e66-a457-f9b0dd6862fe');
  }, []);

  return (
    <Wrapper className="overflow-x-hidden">
      <Seo
        title="bugs.work"
        description="Move fast, stay aligned, and build better - together"
      />

      <Acnavbar
        home={true}
        pathname={''}
        logo={bugsworkfavicon}
        logoLogic={false}
      />
      <Hero
        isScrollAnimationPresent={true}
        headerText="Bugs keeping you awake at night?"
        subHeading="Don't let another bug cost your business! Instantly capture visual feedback and seamlessly integrate with your favourite 3rd party tools."
      />
      <Efficiency />
      <Features />
      <div className="base-color">
        <Integration />
      </div>
      <Clients />
      <AskedQuestions /> 
      <Footer pathname={''} />
    </Wrapper>
  );
};

export default IndexPage;

import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import '../assets/scss/_common.scss';
import faq from '../assets/images/faq.svg';

export default function AskedQuestions() {
  const [isMobileView, setIsMobile] = useState(false);
  const [isPad, setIsPad] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsPad(window.innerWidth >= 1170 && window.innerWidth <= 1230);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial call to set initial values
    handleResize();

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const lineargradient =
    'linear-gradient(-135deg, #ff6900 0%, #ff6900 50%, #fcb900 50%, #fcb900 100%)';

  return (
    <div id="faqs" className="container my-4 my-lg-5">
      <div className={isMobileView ? 'row my-3 mx-2' : 'row'}>
        <h2 className="display-5 ask-ques  fw-600  fnt-32 text-center mb-3 mb-lg-5 base-color">
          Frequently asked questions
        </h2>
        <div className="d-flex flex-lg-row justify-content-lg-between">
          <div className={'col-12 my-3 col-lg-6'}>
            <Accordion className="m-0" defaultActiveKey={null}>
              <Accordion.Item eventKey="0" className="faq-background">
                <Accordion.Header className="accordion-question">
                  <div className={'fs-5 fw-600 p-0 my-2 accordion-header'}>
                    What does bugs.work do?
                  </div>
                </Accordion.Header>
                <Accordion.Body className="pb-3">
                  <div className={'p-1 accordion-body'}>
                    bugs.work is an extensive solution to help you ensure your
                    apps stay bug-free! With bugs.work, you can report bugs with
                    great ease. Share image or video feedback with the
                    developers, and we automatically capture all the technical
                    information such as the platform, system info, console logs
                    and network logs, to make debugging easy.
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="faq-background">
                <Accordion.Header className="accordion-question">
                  <div className={'fs-5 fw-600 p-0 my-2 accordion-header'}>
                    How can bugs.work help my business?
                  </div>
                </Accordion.Header>
                <Accordion.Body className="pb-3">
                  <div className={'p-1 pb-0 accordion-body'}>
                    bugs.work has a proven record to improve QA and developer
                    efficiency. QAs no longer need to spend hours capturing
                    videos, creating tasks, adding technical info or helping
                    developers reproduce a bug. bugs.work makes the whole
                    testing process seamless and easily integrates with your
                    favourite task management tools!
                  </div>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2" className="faq-background">
                <Accordion.Header className="accordion-question">
                  <div className={'fs-5 fw-600  p-0 my-2 accordion-header'}>
                    I don’t use a task management tool. Can I still use
                    bugs.work?
                  </div>
                </Accordion.Header>
                <Accordion.Body className="pb-3">
                  <div className={'p-1 pb-0 accordion-body'}>
                    Absolutely! We offer an extensive, easy-to-use Kanban board
                    which helps you keep track of all your bugs, set priorities,
                    and assign tasks to your team!
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="faq-background">
                <Accordion.Header className="accordion-question">
                  <div className={'fs-5 fw-600  p-0 my-2 accordion-header'}>
                    How does bugs.work help me ensure my customers don’t
                    encounter bugs?
                  </div>
                </Accordion.Header>
                <Accordion.Body className="pb-3">
                  <div className={'p-1 accordion-body'}>
                    Bugs are costly.Don't let another bug cost your business!
                    Instantly capture visual feedback and seamlessly integrate
                    with your favourite 3rd party tools.
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="faq-background">
                <Accordion.Header className="accordion-question">
                  <div className={'fs-5 fw-600  p-0 my-2 accordion-header'}>
                    Sounds great! How do I start?
                  </div>
                </Accordion.Header>
                <Accordion.Body className="pb-3">
                  <div className={'p-1 accordion-body'}>
                    You can get started with a free (no credit card required)
                    account right away! Install our script in your web app, or
                    get the browser extension, to start reporting bugs and
                    creating test flows in no time!
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <div className="fw-500 mt-3 fnt-sm-14">
                Still have doubts? Schedule a{' '}
                <a target="_blank" href="https://calendly.com/bugs-work/15min">
                  <span className="free-meeting me-2">free meeting</span>
                </a>
                with our product experts!
              </div>
            </Accordion>
          </div>
          <div
            className={`${'col-lg-5 d-flex justify-content-center align-items-center my-3'}`}
          >
            <Image
              src={faq}
              className="d-none d-lg-block"
              alt="Responsive Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

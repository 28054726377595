import React, { useEffect, useState } from 'react';
import '../assets/scss/feature.scss';
import { Button, Container } from 'react-bootstrap';

import { Link, navigate } from 'gatsby';
import arrow from '../assets/images/right-arrow-white.svg';

const Features = () => {
  return (
    <div className="feature-section  py-4 py-lg-5">
      <Container>
        <div>
          <div className="fw-600  fnt-sm-14 text-white mb-4 text-center feature-header">
            Discover how bugs.work can make bug reporting feel effortless.
          </div>
          <div className="text-center">
            {/* <iframe
                width="1300"
                height="700"
                src="https://www.youtube.com/embed/TX9qSaGXFyg"
                allowFullScreen
              ></iframe> */}
          </div>
          <div>
            <div className="fw-400 font-14 text-white my-4 mx-auto text-center">
              Seamlessly report bugs and feedback with detailed technical
              insights and test environment data. Eliminate back-and-forth with
              developers.
            </div>
          </div>

          <div className="text-center">
            <Link to={'/visual-feedback'} target="_blank">
              <Button size="lg" className="font-14 fw-500 btn-contact-us">
                Tell me more
                <img src={arrow} alt="arrow" className="ms-2" />
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Features;

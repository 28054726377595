import React, { useEffect, useState } from 'react';
import '../assets/scss/_common.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import '../assets/scss/testimonial.scss';
import arrow from '../assets/images/right-arrow-white.svg';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import person from '../assets/images/person.svg';

export default function TestimonialsDetails({ testimonial }: any) {
  return (
    <div className="d-flex flex-md-row justify-content-md-between align-items-md-center testimonial-slider-section p-4">
      <div className="col-12  d-md-flex flex-row justify-content-between">
        <div className="float-end col-6 col-md-3  col-lg-3 order-2 d-md-flex justify-content-end align-items-start">
          <img
            className="testimonial-img ms-2 ms-md-0"
            src={person}
            alt="testimonail-person"
          />
        </div>
        <div className="order-1  col-md-8 col-xl-9">
          <div className="base-color fnt-sm-12 fnt-18 me-4 me-lg-0 fw-600 lh-20">
            {testimonial?.statement}
          </div>

          <div className="fnt-sm-12 fnt-18 font-italic mt-3 base-color">
            {testimonial?.designation}
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center w-100 mt-4">
            <div>
              <Link to="/reviews">
                <Button
                  size="lg"
                  className={
                    'font-14 fw-500 w-100px d-flex flex-row align-items-center btn-contact-us'
                  }
                >
                  <span>Read all reviews</span>
                  <img src={arrow} alt="arrow" className="ms-2" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
